/** 个人中心-基本信息 */
<template>
  <div class="my-content back-fff">
    <el-form :model="form" :rules="$rules.common" hide-required-asterisk ref="form" label-width="100px"
      class=" flex-warp">
      <el-row :gutter="85">
        <el-col :span="10">
          <el-form-item label="手机号：" prop="phone">
            <span>{{userInfo.phone}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="公司名称：" prop="gsmc" :rules="[{ required: true, message: '公司名称不能为空' }]">
            <el-input v-if="isEdit" v-model="form.gsmc"></el-input>
            <span v-else>{{userInfo.gsmc}}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="85">
        <el-col :span="10">
          <el-form-item label="邮箱：" prop="mailbox">
            <el-input v-if="isEdit" v-model="form.mailbox"></el-input>
            <span v-else>{{userInfo.mailbox}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="营业执照：" prop="image">
            <el-input v-if="isEdit" v-model="license" class="flex">
              <el-upload slot="append" ref="upload" class="upload-demo" action="#" :show-file-list="false"
                :auto-upload="false" :on-change="handleChange" :before-upload="beforeUpload">
                <el-button size="small" class="theme-back">点击上传</el-button>
              </el-upload>
            </el-input>
            <el-button v-else type="text" class="pa-0" @click="open">查看</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="85">
        <el-col :span="10">
          <el-form-item label="姓名：" prop="name">
            <el-input v-if="isEdit" v-model="form.name"></el-input>
            <span v-else>{{userInfo.name}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="所在地区：" prop="province">
            <span>{{userInfo.province}}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="85">
        <el-col :span="10">
          <el-form-item label="性别：" prop="sex" :rules="[{ required: true, message: '性别不能为空' }]">
            <el-input v-if="isEdit" v-model="form.sex"></el-input>
            <span v-else>{{userInfo.sex}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="个人职务：" prop="grzw" :rules="[{ required: true, message: '个人职务不能为空' }]">
            <el-input v-if="isEdit" v-model="form.grzw"></el-input>
            <span v-else>{{userInfo.grzw}}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="85">
        <el-col :span="10">
          <el-form-item label="座机：" prop="tel">
            <el-input v-if="isEdit" v-model="form.tel"></el-input>
            <span v-else>{{userInfo.tel}}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" justify="center">
        <el-col :span="24">
          <el-form-item class="form-btns mt-50">
            <el-button v-if="isEdit" class="theme-back" type="primary" :loading="isLoading" @click="updateUser('form')">
              确定
            </el-button>
            <el-button v-if="isEdit" class="theme-back" type="primary" plain @click="isEdit=false">
              取消
            </el-button>
            <el-button v-else class="theme-back" type="primary" @click="isEdit=true">编辑</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { getUserInfo, updateUserInfo } from '@/api/account'
export default {
  name: 'My',
  data () {
    return {
      isEdit: false,
      isLoading: false,
      license: '',
      userInfo: {
        phone: '',
        gsmc: '',
        mailbox: '',
        image: '',
        name: '',
        province: '',
        sex: '',
        grzw: '',
        tel: '',
      },
      form: {},
      FormDatas: new FormData()
    }
  },
  watch:{
    isEdit(newVal){
      if (!newVal) {
        this.$refs.form.resetFields()
      }else{
         this.form = JSON.parse(JSON.stringify(this.userInfo))
      }
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    async getUserInfo () {
      try {
        let { data, status } = await getUserInfo()
        if (status == 200) {
          for (const key in this.userInfo) {
            this.userInfo[key] = data[key]
            this.license = data.image
          }
          this.form = JSON.parse(JSON.stringify(this.userInfo))
        }
      } catch (error) {
        return false
      }

    },
    // 修改用户信息
    updateUser (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          for (const key in this.form) {
            if (this.form[key] != this.userInfo[key]) {
              this.FormDatas.append(key, this.form[key])
            }
          }
          try {
            let { data, msg, status } = await updateUserInfo(this.FormDatas)
            if (status == 200) {
              this.$message.success('修改成功')
              await this.getUserInfo()
              this.isEdit = false
            } else {
              this.$message.error(msg)
            }
            this.isLoading = false
          } catch (error) {
            this.isLoading = false
          }
        } else {
          return false;
        }
      });
    },
    handleChange (file) {
      this.FormDatas.append('image', file.raw)
      this.license = file.name
    },
    beforeUpload (file) {
      return false
    },
    // 查看营业照
    open () {
      this.$MessageBox.alert(`<img src='${this.$img_base + this.userInfo.image}' max-height="480px"/>`, {
        dangerouslyUseHTMLString: true,
        showClose: false,
        showConfirmButton: false,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        customClass: 'my-message-box'
      }).catch(() => { });
    },
  },
}
</script>
<style lang="scss" scoped>
.my-content {
  padding: 42px 14px;
}
::v-deep .el-form {
  .el-form-item__label {
    font-size: 16px;
    color: #8490ad;
    line-height: 36px;
  }
  .el-form-item__content {
    line-height: 36px;
    font-size: 16px;
    .el-input {
      .el-input__inner {
        flex: 1;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
      .el-input-group__append {
        width: 90px;
        padding: 0;
        border-color: transparent;
        border: none;
        margin-left: 10px;
        border-radius: 4px;
        line-height: 36px;
        overflow: hidden;
        .el-button {
          width: 100%;
          height: 36px;
          padding-left: 10px;
          padding-right: 10px;
          color: #65b1ff;
        }
      }
    }
  }
  .form-btns {
    text-align: center;
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-button {
      padding: 12px 45px;
      border-radius: 20px;
    }
  }
}
::v-deep .el-upload {
  width: 100%;
}
</style>
<style lang="scss">
.my-message-box {
  width: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin-top: -100px;
  .el-message-box__header,
  .el-message-box__content,
  .el-message-box__btns {
    padding: 0;
  }
}
</style>